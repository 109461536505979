
// Styles:
import "../scss/main.scss";

// Other JS
import "./util.js";

const parts = [
    'maidstone', 'pride', 0, '@', undefined, 'gm', undefined, 'ail', '.', 'co', false, 'm'
];

(function ($) {

    var $window = $(window),
        $body = $('body'),
        $header = $('#header'),
        $banner = $('#banner');

    // Breakpoints.
    breakpoints({
        xlarge: '(max-width: 1680px)',
        large: '(max-width: 1280px)',
        medium: '(max-width: 980px)',
        small: '(max-width: 736px)',
        xsmall: '(max-width: 480px)'
    });

    // Play initial animations on page load.
    $window.on('load', function () {
        window.setTimeout(function () {
            $body.removeClass('is-preload');
        }, 100);
    });

    // Header.
    if ($banner.length > 0
        && $header.hasClass('alt')) {

        $window.on('resize', function () {
            $window.trigger('scroll');
        });

        $banner.scrollex({
            bottom: $header.outerHeight(),
            terminate: function () {
                $header.removeClass('alt');
            },
            enter: function () {
                $header.addClass('alt');
            },
            leave: function () {
                $header.removeClass('alt');
            }
        });
    }

    // Menu.
    var $menu = $('#menu');

    $menu._locked = false;

    $menu._lock = function () {

        if ($menu._locked)
            return false;

        $menu._locked = true;

        window.setTimeout(function () {
            $menu._locked = false;
        }, 350);

        return true;
    };

    $menu._show = function () {
        if ($menu._lock())
            $body.addClass('is-menu-visible');
    };

    $menu._hide = function () {
        if ($menu._lock())
            $body.removeClass('is-menu-visible');
    };

    $menu._toggle = function () {
        if ($menu._lock())
            $body.toggleClass('is-menu-visible');
    };

    $menu
        .appendTo($body)
        .on('click', function (event) {
            event.stopPropagation();
            $menu._hide();
        })
        .find('.inner')
        .on('click', '.close', function (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            $menu._hide();
        })
        .on('click', function (event) {
            event.stopPropagation();
        })
        .on('click', 'a', function (event) {
            var href = $(this).attr('href');
            event.preventDefault();
            event.stopPropagation();
            $menu._hide();
            window.setTimeout(function () {
                window.location.href = href;
            }, 350);
        });

    $body
        .on('click', 'a[href="#menu"]', function (event) {
            event.stopPropagation();
            event.preventDefault();
            $menu._toggle();
        })
        .on('keydown', function (event) {
            // Hide on escape.
            if (event.keyCode === 27) $menu._hide();
        });

    initContactLinks();
    initContactForms();

})(jQuery);

function initContactLinks()
{
    for (const $link of $('.contact-link')) {
        $($link).on('click', ev => {
            ev.preventDefault();
            linkClick();
        });
    }
}

function linkClick() {
    window.location.href = mailTo();
}

function mailTo()
{
    return [ 'mai', 0, 'lto', false, ':'].filter(Boolean).join('') + parts.filter(Boolean).join('');
}

function initContactForms()
{
    for (const $form of Array.from(document.querySelectorAll('.contact-form'))) {
        initContactForm($form);
    }
}

function initContactForm($form)
{
    const $f = $($form);

    $f.on('submit', function (ev) {

        ev.preventDefault();

        const $subject = $f.find('input[name="subject"]');
        const $email = $f.find('input[name="email"]');
        const $body = $f.find('textarea[name="body"]');

        let url = mailTo();

        if ($subject.val()) {
            url += '?subject=' + encodeURIComponent($subject.val());
        }
        
        if ($email.val()) {
            url += '&body=' + encodeURIComponent($email.val());
        }

        if ($body.val()) {
            url += '&body=' + encodeURIComponent($body.val());
        }

        window.location.href = url;
    });
}